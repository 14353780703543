const tabs = {
  list: 'Liste',
  traffic: 'Traffic',
};

const list = {
  appointment: 'Rendez-vous',
  actionType: 'Type action',
  cargoDestination: 'Cargo destination',
  cargo: 'Cargo',
  cargoType: 'Type de cargo',
  carrierBill: 'Facture du transporteur',
  carrierName: 'Transporteur',
  company: 'Compagnie',
  date: 'Date',
  description: 'Description',
  driverName: 'Nom chauffeur',
  forWhom: 'Pour qui',
  hazardousMaterial: 'Matières dangereuses',
  inventories: 'Inventaires',
  number: 'Numéro RV',
  orders: 'Commandes',
  parking: 'Stationnement',
  productSubtype: 'Sous-type de produit',
  productType: 'Type de produit',
  purchaseOrder: 'Bon de commande',
  quantities: 'Quantités',
  receivers: 'Destinataire',
  romSomNumbers: 'Numéro récep/expéd',
  site: 'Site',
  status: 'Statut',
  supplierBooking: 'Fournisseur',
  time: 'Heure',
  timeSlot: 'Plage horaire',
  trailerType: 'Type remorque',
  transportationNumber: 'Numéro de transport',
  transportationType: 'Transport',
  vehicleIdentifier: 'Id. Véhicule',
  specialInstructions: 'Instructions spéciales',
};

const transportationType = {
  Barge: 'Barge',
  Other: 'Autre',
  Railcar: 'Train',
  Truck: 'Camion',
  Vessel: 'Bateau',
};

const trailerTypes = {
  bTrain: 'B-Train',
  dryBox: 'Dry Box',
  flatBed: 'Flat Bed',
  frame: 'Frame',
  gooseneck: 'Gooseneck',
  curtainSide: 'Curtain Side',
  dropDeck: 'Drop Deck',
  goldhofer: 'Goldhofer',
  hopperTruck: 'Hopper',
  hopperRailcar: 'Hopper',
  liveBottom: 'Live Bottom',
  triAxle: 'Tri-Axle',
  endDump: 'End Dump',
  boxcars: 'Boxcars',
  bulkhead: 'Bulkhead',
  centerBeams: 'Center Beams',
  flatCars: 'Flat cars',
  gondolasOpen: 'Gondolas Open',
  gondolasCovered: 'Gondolas Covered',
  tanker: 'Tanker',
};

const trailerTypeGroup = {
  truck: 'Truck',
  railCar: 'Railcar',
};

const statuses = {
  Cancelled: 'Annulé',
  Departed: 'Parti',
  InProcessing: 'En cours',
  InQueue: 'En file',
  Loaded: 'Chargé',
  'Loaded-Unloaded': 'Chargé/Déchargé',
  NoShow: 'Pas présenté',
  OnTerminal: 'Sur le terminal',
  Pending: 'En attente',
  Refused: 'Refusé',
  Scheduled: 'Programmé',
  Unloaded: 'Déchargé',
  Unscheduled: 'Non programmé',
};

const tooltips = {
  downloadDocument: 'Télécharger le document.',
  receptionNotAvailable: 'Réception non confirmée. Document non disponible.',
  shipmentNotAvailable: 'Expédition non confirmée. Document non disponible.',
};

const hazardousMaterialExcelValues = {
  yes: 'Oui',
  no: 'Non',
};

const appointment = {
  attachments: 'Pièces jointes',
  appointments: 'Mes rendez-vous',
  exportToExcel: 'Exporter vers Excel',
  hazardous: 'Dangereux',
  viewAttachments: 'Consulter les pièces jointes',
  hazardousMaterialExcelValues,
  list,
  statuses,
  tabs,
  tooManyResultsMessage: 'Vous ne pouvez pas avoir plus de {{number}} résultats de recherche. Veuillez raffiner votre recherche.',
  transportationType,
  trailerTypes,
  trailerTypeGroup,
  tooltips,
};

export default appointment;
