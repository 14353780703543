const cargoType = {
  Breakbulk: 'Cargaisons générales',
  Bulk: 'Vrac',
};

const status = {
  cancelled: 'Annulé',
  confirmed: 'Confirmé',
  draft: 'Brouillon',
  inProgress: 'En cours',
  loaded: 'Chargé',
  paperworkReceived: 'Documents reçus',
  rectification: 'En rectification',
  unloaded: 'Déchargé',
};

const transportationType = {
  Barge: 'Barge',
  Other: 'Autre',
  Railcar: 'Train',
  Truck: 'Camion',
  Vessel: 'Bateau',
};

const reception = {
  appointment: 'Rendez-vous',
  arrival: 'Arrivée',
  arrivalAtFleet: 'Arrivée à la flotte',
  arrivalDate: "Date d'arrivée",
  bargeNumber: 'Numéro de barge',
  billable: 'Facturable',
  cargoType,
  carrier: 'Transporteur',
  carrierBill: 'Facture du transporteur',
  client: 'Client',
  completion: 'Complétion',
  confirmation: 'Confirmation',
  confirmedBy: 'Confirmée par',
  departure: 'Départ',
  driverName: 'Nom du conducteur',
  finishedBy: 'Fini par',
  impactedInventories: 'Inventaires impactés',
  instructions: 'Instructions',
  internalRemarks: 'Remarques internes',
  inventory: 'Inventaire',
  loadingEndDate: 'Date de fin de chargement',
  loadingStartDate: 'Date de début de chargement',
  mainInformation: 'Information principale',
  numberOfRailcars: 'Nombre de trains',
  numberOfTrucks: 'Nombre de camions',
  operationTypeTerms: {
    handling: 'Manutention',
    linesmenOnly: 'Lamaneurs seulement',
    loading: 'Chargement',
    service: 'Service',
    unloading: 'Déchargement',
  },
  paperworkReceivedDate: 'Date de réception des documents',
  pickup: 'Récupération',
  plannedDate: 'Date planifiée',
  plateNumber: 'Numéro de plaque',
  pointOfOrigin: "Point d'origine",
  railcarNumber: 'Railcar Number',
  receivedFrom: 'Reçu de',
  receivedTo: 'Reçu à',
  reception: 'Réception',
  receptionOfMaterial: 'Réception de matériel',
  release: 'Relâche',
  requestedSpotting: 'Repérage demandé',
  specialInstructions: 'Instructions spéciales',
  status,
  takenBy: 'Pris par',
  transloadStevedore: 'Débardeur de transbordement',
  transportation: 'Transport',
  transportationType,
  vesselProject: 'Projet navire',
};

export default reception;
