import { enTranslation } from '@vooban/tc3-core';
import batchActionTypes from '../batchActionTypes/__i18n__/en';
import item from '../item/__i18n__/en';

const containerStatuses = {
  Empty: 'Empty',
  Stuffed: 'Stuffed',
  Sealed: 'Sealed',
};
const packagings = {
  BarrelDrum: 'Barrel/Drum',
  BigBag: 'Big bag',
  Box: 'Box',
  Crate: 'Crate',
  Loose: 'Loose (no packaging)',
  Pallet: 'Pallet',
  StrappedBundle: 'Strapped Bundle',
  Unit: 'Unit',
  Reel: 'Reel',
  Tote: 'Tote',
};
const list = {
  actionNumber: 'Action Number',
  availableQuantity: 'Quantity',
  barcode: 'Barcode',
  billOfLading: 'Bill of Lading',
  booking: 'Booking',
  bookQuantity: 'Book Quantity (MT)',
  branch: 'Branch',
  Breakbulk: 'Breakbulk',
  cargoType: 'Cargo Type',
  clientInventory: {
    name: 'Client Inventory',
    site: {
      name: 'Site',
    },
  },
  containerStatus: 'Container Status',
  customerRelease: {
    released: 'Customer Release',
  },
  customsRelease: {
    number: 'Customs Release',
  },
  descriptionLines: 'Description Lines',
  dimension: 'Dimension',
  description: 'Description',
  diameter: 'Diameter',
  effectiveDate: 'Effective Date',
  financialRelease: {
    released: 'Financial Release',
  },
  footPound: 'Foot Pound',
  freightTon: 'Freight Ton',
  sourceContainer: 'From Container',
  hazardousMaterialInformation: {
    class: 'Hazard Class',
    unNumber: 'UN',
    packagingGroup: 'PG',
  },
  heatNumber: 'Heat Number',
  height: 'Height',
  itemLocation: 'Location',
  itemNumber: 'Item Number 1 & 2',
  length: 'Length',
  lineNumber: 'Line',
  location: 'Location',
  locationHistory: 'Location History',
  lotNumber: 'Lot Number',
  mark: 'Mark',
  movedQuantity: 'Moved Quantity',
  movementDescription: 'Movement',
  order: {
    name: 'Order',
    receiver: {
      name: 'Receiver',
    },
  },
  orderNumber: 'P.O. Number',
  originalVesselProject: 'Vessel Project',
  other: 'Other',
  originalReceptionInformation: { appointmentNumber: 'Appointment Number', receptionNumber: 'Reception Number', receptionDate: 'Reception Date' },
  packaging: 'Packaging',
  priority: 'Priority',
  productType: {
    name: 'Product Type',
  },
  productSubtypeName: 'Product Subtype',
  poNumber: 'PO Number',
  quantity: 'Quantity',
  quantityPerPackage: 'Qty/Pkg',
  remark: 'Remark',
  receivedQuantity: 'Received Qty',
  receiver: {
    name: 'Receiver',
  },
  scope: 'Scope',
  sealNumbers: 'Seal Numbers',
  serialNumber: 'Serial Number',
  shippingCondition: 'Ship. Cond.',
  shippingLineRelease: {
    released: 'Shipping Line Release',
  },
  stuffedIn: 'Containerized in',
  stuffedQuantity: 'Containerized Quantity',
  surfaceAreaPerUnit: 'Surface Area',
  thickness: 'Thickness',
  timestamp: 'Timestamp',
  totalQuantity: 'Book Quantity',
  totalVolume: 'Total Volume',
  totalWeight: 'Total Weight',
  username: 'User',
  volumePerUnit: 'Volume Per Unit',
  volumePerUnitInCubicMeters: 'Volume Per Unit (m³)',
  weightHasBeenVerified: 'As Per Scale',
  weightPerUnit: 'Weight Per Unit',
  weightPerUnitInTons: 'Weight Per Unit (MT)',
  weight: 'Weight',
  width: 'Width',
  containerStatuses,
  packagings,
};

const inventory = {
  attachments: 'Attachments',
  batchActionTypes,
  exportToExcel: 'Export to Excel',
  inventory: 'Inventory',
  inventoryItem: 'Inventory Item {{itemId}}',
  noResultToExportMessage: 'There is no result to export.',
  viewAttachments: 'View attached documents',
  viewMediaFile: 'View media files',
  mediaFiles: 'Media Files',
  item,
  ...enTranslation,
  containerStatuses,
  packagings,
  list,
  tooManyResultsMessage: "You can't have more than {{number}} search results. Please refine your search.",
  title_Breakbulk: 'Item {{id}}',
  title_Bulk: 'Pile {{id}}',
};

export default inventory;
