export const statusesByKey = {
  Cancelled: 'Cancelled',
  Departed: 'Departed',
  InProcessing: 'InProcessing',
  InQueue: 'InQueue',
  Loaded: 'Loaded',
  NoShow: 'NoShow',
  OnTerminal: 'OnTerminal',
  Pending: 'Pending',
  Refused: 'Refused',
  Scheduled: 'Scheduled',
  Unloaded: 'Unloaded',
  Unscheduled: 'Unscheduled',
};

export const statuses = Object.values(statusesByKey);
