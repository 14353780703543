const status = {
  cancelled: 'Cancelled',
  confirmed: 'Confirmed',
  draft: 'Draft',
  inProgress: 'In Progress',
  loaded: 'Loaded',
  paperworkReceived: 'Paperwork Received',
  rectification: 'Rectification',
  unloaded: 'Unloaded',
};

const transportationType = {
  Barge: 'Barge',
  Other: 'Other',
  Railcar: 'Railcar',
  Truck: 'Truck',
  Vessel: 'Vessel',
};

const reception = {
  appointment: 'Appointment',
  arrival: 'Arrival',
  arrivalAtFleet: 'Arrival at Fleet',
  arrivalDate: 'Arrival Date',
  bargeNumber: 'Barge Number',
  billable: 'Billable',
  carrier: 'Carrier',
  carrierBill: "Carrier's Bill",
  client: 'Client',
  completion: 'Completion',
  confirmation: 'Confirmation',
  confirmedBy: 'Confirmed By',
  departure: 'Departure',
  driverName: 'Driver Name',
  finishedBy: 'Finished By',
  impactedInventories: 'Impacted Inventories',
  instructions: 'Instructions',
  internalRemarks: 'Internal Remarks',
  inventory: 'Inventory',
  loadingEndDate: 'Loading End Date',
  loadingStartDate: 'Loading Start Date',
  mainInformation: 'Main Information',
  numberOfRailcars: 'Number of Railcars',
  numberOfTrucks: 'Number of Trucks',
  operationTypeTerms: {
    handling: 'Handling',
    linesmenOnly: 'Linesmen Only',
    loading: 'Loading',
    service: 'Service',
    unloading: 'Unloading',
  },
  paperworkReceivedDate: 'Paperwork Received Date',
  pickup: 'Pickup',
  plannedDate: 'Planned Date & Time',
  plateNumber: 'Plate Number',
  pointOfOrigin: 'Point of Origin',
  railcarNumber: 'Railcar Number',
  receivedFrom: 'Received From',
  receivedTo: 'Received To',
  reception: 'Reception',
  receptionOfMaterial: 'Reception of Material',
  release: 'Release',
  requestedSpotting: 'Requested Spotting',
  specialInstructions: 'Special Instructions',
  status,
  takenBy: 'Taken By',
  transloadStevedore: 'Transload Stevedore',
  transportation: 'Transportation',
  transportationType,
  vesselProject: 'Vessel Project',
};

export default reception;
