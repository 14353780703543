import { Button, Grid, makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Breakpoint from './mediaQuery';

const backToListButtonStyles = makeStyles({
  root: {
    marginLeft: '0.5rem',
  },
});

const headerStyles = (isTabletOrLarger) =>
  makeStyles({
    root: {
      marginBottom: isTabletOrLarger ? '2.5rem' : '1.25rem',
      marginTop: isTabletOrLarger ? '2.5rem' : '1.25rem',
    },
  });

const Header = ({ backToListRoute, subscript, subtitle, superscript, title, right, useDefaultBackToList }) => {
  const isBackToListRouteValid = (backToListRoute && backToListRoute !== '') || useDefaultBackToList;
  const isTabletOrLarger = useMediaQuery(Breakpoint.tablet);
  const { push, goBack } = useHistory();
  const { t } = useTranslation('layout');

  const onBackToListClick = useCallback(() => {
    if (useDefaultBackToList) {
      goBack();
    } else {
      push(backToListRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backToListRoute]);

  return (
    <Grid classes={headerStyles(isTabletOrLarger)()} container alignItems="center">
      {superscript}
      {isBackToListRouteValid ? (
        <>
          <Grid item xs={6} md={6}>
            <span className="flex items-baseline">
              <Typography variant="h1" color="textSecondary" className={'pr-2'}>
                {title}
              </Typography>
              <Typography variant="h2" color="textSecondary">
                {subtitle}
              </Typography>
            </span>
          </Grid>
          <Grid item xs={6} md={6}>
            <div className="flex justify-end">
              {isTabletOrLarger && right}
              <Button classes={backToListButtonStyles()} variant="outlined" color="primary" onClick={onBackToListClick}>
                {t('header.backToList')}
              </Button>
            </div>
          </Grid>
        </>
      ) : (
        <Grid item xs={12} md={12}>
          <Typography variant="h6" color="primary">
            {title}
          </Typography>
        </Grid>
      )}
      <div className="w-full flex">
        {subscript}
        {!isTabletOrLarger && <span className="flex-grow text-right">{right}</span>}
      </div>
    </Grid>
  );
};

Header.propTypes = {
  backToListRoute: PropTypes.string,
  subscript: PropTypes.node,
  superscript: PropTypes.node,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  right: PropTypes.node,
  useDefaultBackToList: PropTypes.bool,
};

Header.defaultProps = {
  backToListRoute: null,
  subscript: null,
  superscript: null,
  right: null,
  subtitle: '',
  useDefaultBackToList: false,
};

export default Header;
