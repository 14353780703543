import { Badge, Grid, Typography, makeStyles } from '@material-ui/core';
import Icon from '@vooban/icon';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Attachments } from '../../../attachment';
import useModal from '../../../hooks/useModal';
import GridHeaderLabel from '../../../layout/GridHeaderLabel';
import Output from '../../../layout/Output';
import OutputCheckbox from '../../../layout/OutputCheckbox';
import Section from '../../../layout/Section';
import Modal from '../../../layout/Modal';
import url from '../attachments/url';
import { InventoryItem } from '../types';

const badgeStyles = makeStyles({
  badge: {
    right: '-0.8em',
    height: '1.5em',
  },
});

const HazardousSection = ({ item }) => {
  const { t } = useTranslation('inventory');
  const [isAttachmentsModalOpen, openAttachmentsModal, closeAttachmentsModal] = useModal(false);

  const hazardousMaterialInformation =
    (item.isStuffableContainer ? item.contentInformation?.hazardousMaterialInformation : item.hazardousMaterialInformation) || [];

  const msdsAttachmentsCount = item.msdsAttachmentIds?.length || 0;
  const imoDgcAttachmentsCount = item.imoDgcAttachmentIds?.length || 0;
  const badgeClasses = badgeStyles();

  const hazardousActions = [
    {
      label: (
        <>
          <Icon id="attachment" className="mr-2" />
          {t('item.hazardousAttachments')}
        </>
      ),
      onClick: openAttachmentsModal,
    },
  ];

  return (
    <Section title={t('item.hazardous')} actions={hazardousActions}>
      <Grid container>
        <Grid item xs={3}>
          <OutputCheckbox checked id="output-hazardous" size="small" label={t('item.hazardous')} />
        </Grid>

        <Grid item xs={9} className="flex justify-end items-center">
          <div className="flex gap-8 mr-4">
            {msdsAttachmentsCount !== 0 && (
              <Badge badgeContent={msdsAttachmentsCount} classes={badgeClasses} color="primary">
                <Typography variant="body2">MSDS</Typography>
              </Badge>
            )}
            {imoDgcAttachmentsCount !== 0 && (
              <Badge badgeContent={imoDgcAttachmentsCount} classes={badgeClasses} color="primary">
                <Typography variant="body2">IMO/DGC</Typography>
              </Badge>
            )}
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: 16 }}>
        <Grid item xs={3}>
          <GridHeaderLabel label={t('item.limitedQuantity')} />
        </Grid>
        <Grid item xs={3}>
          <GridHeaderLabel label={t('item.class')} />
        </Grid>
        <Grid item xs={3}>
          <GridHeaderLabel label={t('item.unNumber')} />
        </Grid>
        <Grid item xs={3}>
          <GridHeaderLabel label={t('item.packagingGroup')} />
        </Grid>
        {hazardousMaterialInformation.map((x, index) => (
          <React.Fragment key={x.id || String(index)}>
            <Grid item xs={3}>
              <OutputCheckbox checked={x.hasLimitedQuantity} id={`output-hazardous-${index}-limitedQuantity`} size="small" />
            </Grid>
            <Grid item xs={3}>
              <Output id={`output-hazardous-${index}-hazardClass`} defaultValue={x.hazardClass} />
            </Grid>
            <Grid item xs={3}>
              <Output id={`output-hazardous-${index}-unNumber`} defaultValue={x.unNumber} />
            </Grid>
            <Grid item xs={3}>
              <Output id={`output-hazardous-${index}-packagingGroup`} defaultValue={x.packagingGroup} />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      <Modal open={isAttachmentsModalOpen} onClose={closeAttachmentsModal} title={t('item.hazardousAttachments')}>
        <Attachments resourceUrl={url(item.clientInventory.id, item.id)} isHazardous />
      </Modal>
    </Section>
  );
};

HazardousSection.propTypes = { item: PropTypes.shape(InventoryItem) };

HazardousSection.defaultProps = { item: {} };

export default HazardousSection;
