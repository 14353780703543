import { containerStatusEnum, shipmentStatusEnum, transactionStatusEnum } from '../../constant';
import identifiers from '../../layout/Identifiers/__i18n__/en';

const list = {
  Adjustment: 'Adjustment',
  DimensionOrWeightModification: 'Dimension & Weight modifications',
  HazmatModification: 'Hazardous modifications',
  IdentifierModification: 'Identifiers modifications',
  MainInformationModification: 'Main Information modifications',
  Move: 'Move',
  MoveFrom: 'Move From',
  MoveTo: 'Move To',
  Merge: 'Merge',
  MergeFrom: 'Merge From',
  MergeTo: 'Merge To',
  MissingItemResolution: 'Missing Item Resolution',
  ReceptionByBarge: 'Reception By Barge',
  ReceptionByContainer: 'Reception By Container',
  ReceptionByOther: 'Reception By Other',
  ReceptionByRailcar: 'Reception By Railcar',
  ReceptionByTruck: 'Reception By Truck',
  ReceptionByVessel: 'Reception By Vessel',
  Relocate: 'Relocate',
  Seal: 'Seal',
  ShipmentByBarge: 'Shipment By Barge',
  ShipmentByOther: 'Shipment By Other',
  ShipmentByRailcar: 'Shipment By Railcar',
  ShipmentByTruck: 'Shipment By Truck',
  ShipmentByVessel: 'Shipment By Vessel',
  Split: 'Split',
  SplitFrom: 'Split From',
  SplitTo: 'Split To',
  Stuff: 'Stuff',
  Transfer: 'Transfer',
  TransferIn: 'Transfer In',
  TransferOut: 'Transfer Out',
  TransferredIn: 'Transferred In',
  TransferredOut: 'Transferred Out',
  Transform: 'Transform',
  TransformFrom: 'Transform From',
  TransformTo: 'Transform To',
  Unseal: 'Unseal',
  Unstuff: 'Unstuff',
  actionDate: 'Action Date',
  actionType: 'Type',
  actionTypeTranslatedValue: 'Type',
  appointment: 'Appointment',
  carrier: 'Carrier',
  carrierBill: 'Carrier Bill',
  containerIdentifier: 'Container Id',
  createdByUserName: 'User',
  effectiveDate: 'Effective Date',
  headerActions: {
    excelFileName: 'Transactions-{{date}}.xlsx',
    exportToExcel: 'Export to Excel',
    getDocuments: 'Get Documents',
    getDocumentsTooManyItemsWarning: 'A maximum of 100 transaction documents can be exported. Please refine your search criteria.',
    getDocumentsNoDocumentsWarning: 'There are no documents available for these transactions.',
    noResultsWarning: 'There is no result.',
    separateDocuments: 'Separate Documents (.zip)',
    singleFile: 'Single File (.pdf)',
    tooManyResultsToExportWarning: "You can't have more than {{number}} search results. Please refine your search.",
  },
  isBillable: 'Billable',
  mainInventoriesNames: 'Main Inventory',
  mainOrdersNames: 'Main Order',
  number: 'Action Number',
  numberOfItems: 'Items',
  otherInventoriesNames: 'Other Inventory',
  otherOrdersNames: 'Other Order',
  productSubtypeNames: 'Product Subtype',
  productTypeNames: 'Product Type',
  projectNumberAndName: 'Project Number',
  quantity: 'Total Qty',
  receiver: 'Destination',
  remarks: 'Remarks',
  site: {
    name: 'Site',
  },
  vehicleIdentifier: 'Vehicle Identifier',
  status: 'Status',
};

const dimensionTypes = {
  height: 'Height',
  weight: 'Weight',
  width: 'Width',
  length: 'Length',
  footpound: 'Foot Pound',
  thickness: 'Thickness',
  diameter: 'Diameter',
  mass: 'Mass',
  surfaceArea: 'Surface Area',
};

const packagings = {
  barrelDrum: 'Barrel/Drum',
  bigBag: 'Big bag',
  box: 'Box',
  crate: 'Crate',
  loose: 'Loose (no packaging)',
  pallet: 'Pallet',
  strappedBundle: 'Strapped Bundle',
  unit: 'Unit',
  reel: 'Reel',
  tote: 'Tote',
};

const itemFinder = {
  destinationName: 'Destination Name',
  vesselLocation: 'Vessel Location',
  clientName: 'Client Name',
  clientInventory: 'Client Inventory',
  order: 'Order',
  productTypeName: 'Product Type',
  productSubtypeName: 'Product Subtype',
  quantity: 'Quantity',
  parentContainerIdentifier: 'Containerized In',
  containerStatus: `Container Status`,
  sealNumbers: `Seal Numbers`,
  description: 'Description',
  poNumber: 'PO Number',
  lotNumber: 'Lot Number',
  serialNumber: 'Serial Number',
  heatNumber: 'Heat Number',
  mark: 'Mark',
  scope: 'Scope',
  billOfLading: 'Bill Of Lading',
  barcode: 'Barcode',
  booking: 'Booking',
  other: 'Other',
  weightPerUnit: 'Weight Per Unit',
  totalWeight: 'Total Weight',
  unitOfWeight: 'Unit Of Weight',
  volumePerUnit: 'Volume Per Unit',
  totalVolume: 'Total Volume',
  unitOfVolume: 'Unit Of Volume',
  surfaceAreaPerUnit: 'Surface Area Per Unit',
  unitOfSurfaceArea: 'Unit Of Surface Area',
  freightTon: 'Freight Ton',
  dimension: 'Dimension',
  diameter: 'Diameter',
  diameterUnit: 'Diameter Unit',
  footPound: 'Foot Pound',
  footPoundUnit: 'Foot Pound Unit',
  length: 'Length',
  lengthUnit: 'Length Unit',
  width: 'Width',
  widthUnit: 'Width Unit',
  height: 'Height',
  heightUnit: 'Height Unit',
  thickness: 'Thickness',
  thicknessUnit: 'Thickness Unit',
  quantityPerPackage: 'Quantity Per Package',
  packaging: 'Packaging',
  un: 'UN',
  pg: 'PG',
  weightHasBeenVerified: 'As Per Scale',
  customsRelease: 'Customs Release',
  shippingLineRelease: 'Shipping Line Release',
  financialRelease: 'Financial Release',
  customerRelease: 'Customer Release',
  released: 'Released',
  onHold: 'On Hold',
  addedBy: 'Added By',
  addedOn: 'Added On',
  status: 'Status',
  vessel: 'Vessel',
  actualDeckLocation: 'Actual Deck Location',
  loadedQuantity: 'Loaded Quantity',
  yes: 'Yes',
  no: 'No',
};

const report = {
  ...list,
  effectiveDateFrom: 'Effective Date From',
  effectiveDateTo: 'To',
  or: 'Or',
  textQuery: 'Text Query',
  min: 'Min',
  max: 'Max',
  any: 'Any',

  actionType: list.actionTypeTranslatedValue,
  site: list.site.name,
  mainInventory: list.mainInventoriesNames,
  mainOrder: list.mainOrdersNames,
  otherInventory: list.otherInventoriesNames,
  otherOrder: list.otherOrdersNames,
  productSubtype: list.productSubtypeNames,
  productType: list.productTypeNames,
  project: list.projectNumberAndName,
};

const containerStatuses = {
  [containerStatusEnum.Empty]: 'Empty',
  [containerStatusEnum.Stuffed]: 'Stuffed',
  [containerStatusEnum.Sealed]: 'Sealed',
};

const shipmentStatus = {
  [shipmentStatusEnum.OnBoard]: 'On Board',
  [shipmentStatusEnum.PartiallyOnBoard]: 'Partially on Board',
  [shipmentStatusEnum.None]: 'None',
};

const transactionStatus = {
  [transactionStatusEnum.Cancelled]: 'Cancelled',
  [transactionStatusEnum.Confirmed]: 'Confirmed',
  [transactionStatusEnum.Draft]: 'Draft',
  [transactionStatusEnum.InProgress]: 'In Progress',
  [transactionStatusEnum.Loaded]: 'Loaded',
  [transactionStatusEnum.PaperworkReceived]: 'Paperwork Received',
  [transactionStatusEnum.Rectification]: 'Rectification',
  [transactionStatusEnum.Unloaded]: 'Unloaded',
};

const error = {
  missingExcel: 'Missing Excel',
};

const excelConfirmationModal = {
  title: 'Export to Excel',
  message: 'The Excel report will be sent to you by email at {{email}}.',
  ok: 'Ok',
};

const transaction = {
  actionDateFrom: 'Action Date From',
  advancedFilters: 'Advanced Filters',
  attachments: 'Attachments',
  disabledDocumentTooltip: 'Document will be available after confirmation of the complete reception',
  disabledExcelTooltip: 'Excel will be available after confirmation of the complete reception',
  effectiveDateFrom: 'Effective Date From',
  itemModifications: 'Item Modifications',
  list,
  partiallyConfirmed: 'partially confirmed',
  search: 'Search',
  to: 'To',
  transactions: 'Transactions',
  viewAttachments: 'View attached documents',
  viewMediaFile: 'View media files',
  viewDocument: 'View the PDF document',
  viewExcel: 'View the Excel document',
  viewTransaction: 'View Transaction',
  mediaFiles: 'Media Files',
  booleanToYesNo: {
    true: 'Yes',
    false: 'No',
  },
  error: {
    missingDocument: 'No Document Found',
  },
  packagingType: 'Packaging Type',
  packagings,
  remark: 'Remark',
  ...itemFinder,
  containerStatuses,
  shipmentStatus,
  transactionStatus,
  ...error,
  excelConfirmationModal,
  report,
  dimensionTypes,
  identifiers,
};

export default transaction;
