const status = {
  cancelled: 'Annulé',
  confirmed: 'Confirmé',
  draft: 'Brouillon',
  inProgress: 'En cours',
  loaded: 'Chargé',
  paperworkReceived: 'Documents reçus',
  rectification: 'En rectification',
  completed: 'Complété',
};

const transportationType = {
  Barge: 'Barge',
  Other: 'Autre',
  Railcar: 'Train',
  Truck: 'Camion',
  Vessel: 'Bateau',
};

const destination = {
  linkedInventoriesAndOrders: 'INVENTAIRES & COMMANDES LIÉS',
  inventory: 'Inventaire',
  order: 'Commande',
  destinations: 'Destinations',
  contact: 'Contact',
};

const shipment = {
  status,
  transportationType,
  ...destination,
  shipmentOfMaterial: 'Expédition de matériel',
  shipmentDate: "Date d'expédition",
  confirmedBy: 'Confirmée par',
  confirmation: 'Confirmation',
  billable: 'Facturable',
  receiver: 'Destinataire',
  orderName: 'Commande',
  portOfDischarge: 'Port de déchargement',
  destinationName: 'Nom de la destination',
  name: 'Nom',
  address: 'Adresse',
  shipper: 'EXPÉDITEUR',
  careOf: 'A/S',
  consignee: 'CONSIGNATAIRE',
  notify: 'NOTIFIER',
  instructions: 'Instructions',
  mainInformation: 'Information principale',
  specialInstructions: 'Instructions spéciales',
  identifier: 'Identifiant',
  operationTypeTerms: {
    linesmenOnly: 'Lamaneurs seulement',
    loading: 'Chargement',
    unloading: 'Déchargement',
  },
  somHasOriginalItemModified:
    "Certaines définitions d'articles ont été changés après l'expédition. Une rectification peut être nécessaire pour mettre à jour ces articles et produire une documentation exacte.",
  vesselProject: 'Projet navire',
};

export default shipment;
