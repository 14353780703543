import { TagInputFilter } from '@vooban/datagrid';
import Dimension from '../../../../common/dimension';
import { formatClass, formatPackagingGroup, formatUnNumber } from '../../../../common/hazardouz';
import columnsSetting from '../../../../layout/Datagrid/ColumnSetting';
import { formatNumber } from '../../../../common/helpers/numbers';

const round = (value) => value !== null && value !== undefined && formatNumber(value);

const volumeFormatter = (volume, _, t) => volume && volume.value !== null && volume.unit && `${round(volume.value)} ${t(`units.${volume.unit}`)}`;

const getDefaultColumns = ({ clientInventories = [], productTypes = [], productSubtypes = [], packagingOptions = [], sites = [] }) =>
  [
    columnsSetting('clientInventory.site.name').withSortKey('site').withFilter({ component: TagInputFilter, keyFilter: 'site', options: sites }),
    columnsSetting('clientInventory.name').withFilter({
      keyFilter: 'clientInventory',
      component: TagInputFilter,
      options: clientInventories,
    }),
    columnsSetting('productType.name').withFilter({
      keyFilter: 'productType',
      component: TagInputFilter,
      options: productTypes,
    }),
    columnsSetting('productSubtypeName').withFilter({
      keyFilter: 'productSubtype',
      component: TagInputFilter,
      options: productSubtypes,
    }),
    columnsSetting('weightPerUnit').withColName('weight').withSortKey('weightPerUnitInTons').withCustomFormat(Dimension.format).withAlignRight(),

    columnsSetting('packaging')
      .withFilter({
        component: TagInputFilter,
        options: packagingOptions,
      })
      .withTranslationValue('packagings'),
    columnsSetting('dimension').withAlignRight(),
    ...['availableQuantity', 'quantityPerPackage'].map((x) => columnsSetting(x).withAlignRight()),
  ].map((x) => x.withIsDefault());

const otherColumns = ({ containerStatuses = [], originalVesselProjects = [] }) => [
  columnsSetting('containerStatus')
    .withFilter({
      component: TagInputFilter,
      options: containerStatuses,
    })
    .withTranslationValue('containerStatuses'),
  columnsSetting('originalVesselProject').withFilter({
    component: TagInputFilter,
    options: originalVesselProjects,
  }),
  ...[
    'originalReceptionInformation.appointmentNumber',
    'barcode',
    'billOfLading',
    'booking',
    'customsRelease.number',
    'description',
    'financialRelease.released',
  ].map(columnsSetting),
  columnsSetting('freightTon').withCustomFormat(round).withAlignRight(),
  ...[
    'heatNumber',
    'lotNumber',
    'mark',
    'order.name',
    'other',
    'stuffedIn',
    'poNumber',
    'remark',
    'scope',
    'serialNumber',
    'shippingLineRelease.released',
    'weightHasBeenVerified',
  ].map(columnsSetting),
  columnsSetting('originalReceptionInformation.receptionNumber').withSortKey('receptionNumber'),
  columnsSetting('receiver.name').withSortKey('receiver'),
  columnsSetting('itemLocation').withSortKey('location'),
  columnsSetting('originalReceptionInformation.receptionDate').withSortKey('receptionDate').withFormatDate(),
  columnsSetting('hazardousMaterialInformation.class').withCustomFormat((_, row) => formatClass(row['hazardousMaterialInformation'])),
  columnsSetting('hazardousMaterialInformation.unNumber').withCustomFormat((_, row) => formatUnNumber(row['hazardousMaterialInformation'])),
  columnsSetting('hazardousMaterialInformation.packagingGroup').withCustomFormat((_, row) => formatPackagingGroup(row['hazardousMaterialInformation'])),
  columnsSetting('volumePerUnitInCubicMeters').withSuffix(' m³').withAlignRight(),
  columnsSetting('volumePerUnit').withCustomFormat(volumeFormatter).withAlignRight(),

  columnsSetting('weightPerUnitInTons').withCustomFormat(round).withAlignRight().withSuffix(' MT'),
  ...['width', 'length', 'height', 'diameter', 'thickness', 'footPound'].map((x) => columnsSetting(x).withCustomFormat(Dimension.format).withAlignRight()),
];

const getColumns = (options) => [...getDefaultColumns(options), ...otherColumns(options)];

export default getColumns;
