import { Grid, Link } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { CARGO_TYPE } from '../../../cargoType';
import Output from '../../../layout/Output';
import Section from '../../../layout/Section';
import { getStuffingCapacity } from '../helpers/itemHelpers';
import { InventoryItem } from '../types';
import DimensionWeightSection from './DimensionWeightSection';
import HazardousSection from './HazardousSection';
import IdentifiersSection from './IdentifiersSection';
import LocationHistoryModal from './modals/LocationHistoryModal';
import SealHistoryModal from './modals/SealHistoryModal';
import SealNumbersModal from './modals/SealNumbersModal';
import ProductCharacteristicsSection from './ProductCharacteristicsSection';

const GeneralInformationPage = ({ item }) => {
  const { t } = useTranslation('inventory');
  const { inventoryId, itemId } = useParams();
  const { url } = useRouteMatch();
  const { push, goBack } = useHistory();

  const locationHistoryAction = {
    label: t('item.history'),
    onClick: () => {
      push(`${url}/location-history`);
    },
  };

  const containerStuffingCapacity = useMemo(() => {
    if (!item) return;
    return getStuffingCapacity(item);
  }, [item]);

  return (
    <>
      <Section title={t('item.mainInformation')} data-testid="section_mainInformation">
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <Output id="output-productType" label={t('item.productType')} defaultValue={item.productType?.name} />
          </Grid>
          <Grid item xs={6} md={3}>
            <Output id="output-productSubtype" label={t('item.productSubtype')} defaultValue={item.productSubtypeName} />
          </Grid>
          <Grid item xs={6} md={3}>
            <Output id="output-packaging" label={t('item.packaging')} defaultValue={item.packaging && t(`packagings.${item.packaging}`)} />
          </Grid>
          {item.cargoType === CARGO_TYPE.bulk && (
            <Grid item xs={6} md={3}>
              <Output id="output-bookQty" label={t('item.bookQuantity')} defaultValue={`${item.availableQuantity} MT`} />
            </Grid>
          )}
          {item.cargoType === CARGO_TYPE.breakbulk && (
            <>
              <Grid item xs={6} md={3}>
                <Output id="output-qtyPerPackage" label={t('item.qtyPerPackage')} defaultValue={item.quantityPerPackage} />
              </Grid>
              <Grid item xs={6} md={3}>
                <Output id="output-order" label={t('item.order')} defaultValue={item.order?.name} />
              </Grid>
              <Grid item xs={6} md={3}>
                <Output id="output-quantity" label={t('item.quantity')} defaultValue={item.quantity} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Output id="output-originalVesselProject" label={t('item.originalVesselProject')} defaultValue={item.originalVesselProject} />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2} wrap="wrap">
                  <Grid item xs>
                    <Output
                      id="output-containerStuffingCapacity"
                      label={t('item.containerStuffingCapacity')}
                      defaultValue={t(`item.stuffingCapacity.${containerStuffingCapacity}`)}
                    />
                  </Grid>
                  {(item.isNonStuffableContainer || item.isStuffableContainer) && (
                    <Grid container item sm={8} alignItems="center" spacing={2}>
                      {item.contentInformation?.seal && (
                        <Grid item>
                          <Link className="p-1" component={RouterLink} color="primary" data-testid="btn-view-seal-numbers" to={`${url}/seal-numbers`}>
                            {t('item.seal.viewSealNumbers')}
                          </Link>
                        </Grid>
                      )}

                      <Grid item>
                        <Link className="p-1" component={RouterLink} color="primary" data-testid="btn-view-seal-history" to={`${url}/seal-history`}>
                          {t('item.seal.viewSealHistory')}
                        </Link>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Output id="output-remarks" label={t('item.remarks')} defaultValue={item.remark} />
          </Grid>
        </Grid>
      </Section>

      <IdentifiersSection item={item} />

      {item.cargoType === CARGO_TYPE.bulk && <ProductCharacteristicsSection item={item} />}

      <Section title={t('item.location')} actions={[locationHistoryAction]} data-testid="section_location">
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <Output id="output-storageFacility" label={t('item.storageFacility')} defaultValue={item.location?.storageFacility?.name} />
          </Grid>
          <Grid item xs={6} md={3}>
            <Output id="output-storageArea" label={t('item.storageArea')} defaultValue={item.location?.storageArea?.name} />
          </Grid>
        </Grid>
      </Section>

      {item.cargoType === CARGO_TYPE.breakbulk && (
        <>
          <DimensionWeightSection item={item} />
          {!!item.hazardousMaterialInformation?.length && <HazardousSection item={item} />}
        </>
      )}

      <Switch>
        <Route exact path={`${url}/seal-history`} render={() => <SealHistoryModal inventoryId={inventoryId} itemId={itemId} onExited={goBack} />} />
        <Route exact path={`${url}/location-history`} render={() => <LocationHistoryModal locationHistory={item?.locationHistory} onExited={goBack} />} />
        <Route
          exact
          path={`${url}/seal-numbers`}
          render={() => item?.contentInformation?.seal && <SealNumbersModal seal={item.contentInformation.seal} onExited={goBack} />}
        />
        <Redirect to={url} />
      </Switch>
    </>
  );
};

GeneralInformationPage.propTypes = {
  item: PropTypes.shape(InventoryItem).isRequired,
};

export default GeneralInformationPage;
