export const formatClass = (hazardous) => {
  if (!hazardous || !hazardous.length) return null;
  // eslint-disable-next-line array-callback-return
  return hazardous.map((x) => (x.hasLimitedQuantity ? `LTD ${x.hazardClass}` : x.hazardClass)).join('; ');
};

export const formatUnNumber = (hazardous = []) => {
  return hazardous.map((x) => x.unNumber).join('; ');
};

export const formatPackagingGroup = (hazardous = []) => hazardous.map((x) => x.packagingGroup).join('; ');
