import { Button, Grid, Typography } from '@material-ui/core';
import Icon from '@vooban/icon';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useAttachmentsCount } from '../../../attachment/useGetAttachments';
import CargoTypeBadge from '../../../cargoType/CargoTypeBadge';
import { UrlBuilder } from '../../../common/HashedRouter';
import { primaryMain } from '../../../CssTemplate';
import Header from '../../../layout/Header';
import LoadingIndicator from '../../../layout/loading/LoadingIndicator';
import OperationalSiteBadge from '../../../operationalSite/OperationalSiteBadge';
import url from '../attachments/url';
import Identifier from '../Identifiers/Identifier';
import { InventoryItem } from '../types';
import useGetItemPackingListDocument from './useGetItemDocument';

const attachmentPattern = UrlBuilder.buildPattern('/attachments/:inventoryId/item/:itemId');

const ClientInventoryItemHeader = ({ item }) => {
  const { t } = useTranslation('inventory');
  const { push, location } = useHistory();
  const [downloadPackingListDocument, , isGenerating] = useGetItemPackingListDocument();
  const { inventoryId, itemId } = useParams();
  const [refreshAttachmentsCount, count] = useAttachmentsCount();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRefreshCount = useCallback(() => refreshAttachmentsCount(url(inventoryId, itemId), new URLSearchParams()), []);

  useEffect(() => {
    handleRefreshCount();
  }, [handleRefreshCount, inventoryId, itemId]);

  const onAttachmentsClick = () => {
    push(UrlBuilder.buildUrl(location, attachmentPattern, { inventoryId: inventoryId, itemId: itemId }));
    handleRefreshCount();
  };

  const handleGeneratePackingListClick = useCallback(() => {
    const barcodeIdentifier = Identifier.toTupleArray(item).find((identifier) => identifier[0] === 'Barcode');
    const barcode = barcodeIdentifier ? barcodeIdentifier[1] : '';
    const filename = barcode ? `PL-${item.clientInventory.name}-${barcode}.pdf` : `PL-${item.clientInventory.name}.pdf`;
    downloadPackingListDocument(inventoryId, itemId, filename);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadPackingListDocument, t]);

  const { clientInventory = {}, isStuffableContainer, originalInventoryItemId } = item;

  return (
    <>
      {isGenerating && <LoadingIndicator />}
      <Header
        hasMarginBottom={false}
        right={
          <>
            {isStuffableContainer && (
              <Button className="relative self-center" variant="outlined" color="primary" onClick={handleGeneratePackingListClick}>
                {t('item.generatePackingList')}
              </Button>
            )}
            <Button type="button" className="relative self-center" onClick={onAttachmentsClick}>
              <Icon id="attachment" className="mr-2" />
              <Typography color="textSecondary">{t('attachments')}</Typography>
              <sup style={{ backgroundColor: primaryMain }} className="rounded-full pl-1 pr-1 text-xs text-white items-center ml-1">
                {count}
              </sup>
            </Button>
          </>
        }
        superscript={
          <Grid item xs={12} md={12}>
            <Typography color="textSecondary">{clientInventory.name}</Typography>
          </Grid>
        }
        subtitle={`${originalInventoryItemId || ''}`}
        title={t('inventoryItem')}
        subscript={
          <>
            {clientInventory.cargoType && <CargoTypeBadge cargoType={clientInventory.cargoType} marginTop="0.5rem" />}
            {clientInventory.site?.name && <OperationalSiteBadge operationalSiteName={clientInventory.site?.name} marginTop="0.5rem" />}
          </>
        }
        useDefaultBackToList
      />
    </>
  );
};

ClientInventoryItemHeader.propTypes = {
  item: PropTypes.shape(InventoryItem),
  itemId: PropTypes.number,
  attachmentCount: PropTypes.number,
  onAttachmentsClick: PropTypes.func,
};

ClientInventoryItemHeader.defaultProps = {
  item: {},
  attachmentCount: 0,
  itemId: undefined,
  onAttachmentsClick: () => undefined,
};

export default ClientInventoryItemHeader;
