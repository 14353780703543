import { Chip } from '@material-ui/core';
import { toOption } from '../../common/helpers/list/options';
import { transactionStatusColor, transactionStatusEnum } from '../../constant';

const fromLabelValue = ({ key, value }) => ({ key, label: value, value: key });
const translateStatus = (status, t) => t(`transactionStatus.${status}`);

export const getTransactionListOptions = (options, t) => ({
  actionTypes: (options?.actionTypes || []).map(fromLabelValue),
  mainInventories: (options?.mainInventories || []).map(toOption()),
  productTypes: (options?.productTypes || []).map(toOption()),
  productSubtypes: (options?.productSubtypes || []).map(toOption()),
  otherInventories: (options?.otherInventories || []).map(toOption()),
  sites: (options?.sites || []).map(toOption()),
  projects: (options?.projects || []).map(toOption()),
  receivers: (options?.receivers || []).map(toOption()),
  mainOrders: (options?.mainOrders || []).map(toOption()),
  otherOrders: (options?.otherOrders || []).map(toOption()),
  statuses: (options?.statuses || []).map((status) => ({ label: translateStatus(status, t), value: status })),
});

export const getStatusPill = (status, row, t) => {
  const actionType = row.actionType.toLowerCase();
  const isRomOrSom = actionType.includes('reception') || actionType.includes('shipment');
  const defaultStatusToConfirmed = !status || !isRomOrSom;
  if (defaultStatusToConfirmed) {
    status = transactionStatusEnum.Confirmed;
  }
  return <Chip style={{ backgroundColor: transactionStatusColor[status] }} label={translateStatus(status, t)} className="grid -center" />;
};
